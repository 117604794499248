/*
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2025-01-21 23:22:47
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-02-12 18:20:32
 * @FilePath: /ww-swisstyle/src/api/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
import { get } from 'jquery';

// 创建axios实例
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : 'http://47.94.249.54:18080/dev-api',
  timeout: 50000
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在这里可以添加请求头等配置
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export const api = {

  
  // 获取轮播图数据
  getCarouselData(data) {
    return instance.get('/system/carousel/list', {
      params: {
        pageSize: 100,
        pageNum: 1,
      }
    });
  },
  
  // 获取服务列表
  getServices() {
    return instance.get('/system/productItem/list', {
      params: {
        pageSize: 100,
        pageNum: 1,
        productId: 'service'
      }
    });
  },
  
  // 获取客户列表
  getClients() {
    return instance.get('/system/productItem/list', {
      params: {
        pageSize: 1000,
        pageNum: 1,
        productId: 'logo'
      }
    });
  },
  
  getProductList(type) {
    return instance.get('/system/products/list', {
      params: {
        pageSize: 1000,
        pageNum: 1,
        brand: type
      }
    });
  },

  getProductDetail(data) {
    return instance.post('/system/products/getById', data);
  },

  getProductItemList(data) {
    return instance.get('/system/productItem/list', {
      params: data
    });
  }

};

export default api; 
