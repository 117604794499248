/*
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2025-01-20 20:26:00
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-03-17 22:08:28
 * @FilePath: /ww-swisstyle/src/lang/zh.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    common: {
      hello: '你好',
      welcome: '欢迎来到我们的网站'
    },
    nav: {
      home: '首页',
      about: '关于我们',
      clients: '客户分布',
      team: '团队',
      contact: '联系我们',
      swiss: '瑞士总部'
    },
    home: {
      welcome: '欢迎来到我们的应用',
      home: '首页',
      about: '关于',
      contact: '联系我们',
      banner: {
        title: 'Swisstyle包装解决方案',
        subtitle: '为您提供专业的包装设计与制造服务',
        contact: '联系我们'
      },
      services: {
        title: '我们的服务',
        items: [
          {
            title: '包装设计',
            desc: '专业的包装设计团队，为您提供创新的包装解决方案'
          },
          {
            title: '制造服务',
            desc: '先进的制造工艺，确保产品品质'
          },
          {
            title: '全球配送',
            desc: '覆盖全球的物流网络，快速可靠'
          }
        ]
      },
      about: {
        title: '关于我们',
        desc: 'Swisstyle是一家专注于包装解决方案的瑞士公司，我们致力于为客户提供最优质的服务。',
        features: [
          '专业的设计团队',
          '先进的制造工艺',
          '严格的品质控制',
          '优质的客户服务'
        ]
      },
      contact: {
        title: '联系我们',
        desc: '扫描下方二维码，了解更多详情'
      },
      carousel: {
        learnMore: '了解更多',
        slide1: {
          title: '创新包装解决方案',
          desc: '为您的产品提供独特的包装设计，提升品牌价值'
        },
        slide2: {
          title: '全球物流网络',
          desc: '覆盖全球的配送网络，确保您的产品准时送达'
        },
        slide3: {
          title: '可持续发展',
          desc: '环保材料，绿色包装，为地球减负'
        }
      }
    },
    footer: {
      swiss: {
        title: '瑞士'
      },
      china: {
        title: '中国'
      },
      followUs: '关注我们',
      scanQR: '扫描二维码关注公众号'
    },
    aboutUs: {
      desc1: '我们是瑞士格美包装设计公司，出于对包装设计的热爱，五十年前，我建立了自己的公司。创业伊始，我们就有幸与许多世界知名品牌合作，为不同的产品提供外包装的创意和设计，特别是在食品包装设计领域。由于我们非凡的创作才能和杰出的专业水准，屡屡为我们的客户带来商业上有力的帮助和不断的惊喜，使得我们公司在整个欧洲声誉斐然',
      highlight: '一次偶然在北京旅游的机会，它们的魅力让我无法抗拒，我一下子爱上了中国。',
      desc2: '一次偶然在北京旅游的机会，使我有机会接近和体会中国，中国的书法、绘画、瓷器、烹饪、思想以及源远流长的传统文化，它们的魅力让我无法抗拒，我一下子就爱上了中国。同时，专业的观察与思考方式使然，我也看到了在中国当今迅速发展的商业环境下，大多数的包装设计还处于相当简单的状态，甚至一些模仿之作，不能非常有效的传达产品的内涵，不能有效地抓住消费者的购买欲，或者说其包装不能成为产品可靠的"无声的推销员"。很显然，在琳琅满目的超市，如果企业的产品包装特别是食品包装不能有效的使消费者产生拥有的欲望，会极大的抑制企业发展的速度，这是令人非常遗憾的事。当然，实现产品包装的出类拔萃，也是一个非常艰苦的专业化的设计过程，而且是一个迅速发展的动态的思维过程。',
      desc3: '2005年6月我在中国成立了一个分公司，同时建立了一个功能强大的工作站和自主服务器，能够实现瑞士和中国的同步设计，这样，我们就能将欧洲和国际上更新更有效的设计同步地和中国的市场需求结合起来，更好地为中国的企业和消费者服务。',
      desc4: '包装设计，在于孜孜不倦地尝试与探索，追求人类生活的美好情怀。色彩是极具价值的，它对我们表达思想、情趣、爱好的影响是最直接、最重要的、把握色彩，感受设计、创造美好包装，丰富我们的生活，是我们这个时代所需。无彩色设计的包装犹如尘埃喧闹中的一丝宁静，它的高雅、质朴、沉静使人在享受酸、甜、苦、辣、咸后，回味着另一种清爽、淡雅的幽香，他们不显不争的属性特征将会在包装设计中散发着永恒的魅力。',
      desc5: '如果能和中国企业家在中国国际化进程中共同发展，那将是一个非常令人高兴的事情。',
      signature: '公司总裁 格美'
    }
  };
  
  