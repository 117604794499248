<!--
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2024-12-02 15:23:46
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-03-17 22:02:28
 * @FilePath: /ww-swisstyle/src/App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/logo.png" alt="Swisstyle" height="50">
        </router-link>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" 
                data-target="#navbarNav" aria-controls="navbarNav" 
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

         <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/index" @click.native="collapseNav">{{ $t('nav.home') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/aboutUs" @click.native="collapseNav">{{ $t('nav.about') }}</router-link>
            </li>
           
            <li class="nav-item">
              <router-link class="nav-link" to="/team" @click.native="collapseNav">{{ $t('nav.team') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contact" @click.native="collapseNav">{{ $t('nav.contact') }}</router-link>
            </li>
            <!-- <li class="nav-item">
              <a href="https://www.geymeier-packaging.ch/" class="nav-link"
              target="_blank" @click="collapseNav">{{ $t('nav.swiss') }}</a>
            </li> -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="langDropdown" role="button"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ currentLang }}
              </a>
              <div class="dropdown-menu" aria-labelledby="langDropdown">
                <a class="dropdown-item" href="#" @click="switchLang('zh')">中文</a>
                <a class="dropdown-item" href="#" @click="switchLang('en')">English</a>
              </div>
            </li>
          </ul>
        </div> 
      </div>
    </nav>

    <div class="content-wrapper">
      <router-view/>
    </div>

    <!-- 页脚 -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <!-- 瑞士总部信息 -->
          <div class="col-lg-4 mb-4">
            <div class="footer-section">
              <h5><img src="@/assets/swiss-flag.png" alt="Swiss" class="flag-icon"> {{ $t('footer.swiss.title') }}</h5>
              <p>Suisse Office:              </p>
              <p>SWISSTYLE   GRAPHIC  DESIGN COMMUNICATION & PREPRESS</p>
              <p>Avenue de la Gare 77 1022 Chavannes-près-Renens Vaud / Suisse</p>
              <p>Courriel: info@swisstyle.com              </p>
              <p>Tél: +41 21 888 32 87</p>
            </div>
          </div>

          <!-- 中国分公司信息 -->
          <div class="col-lg-4 mb-4">
            <div class="footer-section">
              <h5><img src="@/assets/china.png" alt="China" class="flag-icon"> {{ $t('footer.china.title') }}</h5>
              <p>北京瑞思格美艺术设计有限公司</p>
              <p>地址：北京市东城区安化北里北京上舍家园2号楼</p>
              <p>E-mail: info@swisstyle.com</p>
              <p>电话：+86 10 6713 1524   +86 10 8755 1160(市场部)</p>
              <p>手机：13911579496 (同微信)</p>
            </div>
          </div>

          <!-- 公众号二维码 -->
          <div class="col-lg-4 mb-4">
            <div class="footer-section text-center">
              <h5>{{ $t('footer.followUs') }}</h5>
              <div class="qr-code">
                <img src="@/assets/qr-code.jpg" alt="WeChat QR Code">
                <p>{{ $t('footer.scanQR') }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 底部链接和版权信息 -->
        <div class="footer-bottom">
          <div class="row">
            <div class="col-md-8">
              <ul class="footer-links">
                <li @click="linkHandler('/aboutUs')"><a href="#">{{ $t('nav.about') }}</a></li>
                <li @click="linkHandler('/team')"><a href="#">{{ $t('nav.team') }}</a></li>
                <li @click="linkHandler('/contact')"><a href="#">{{ $t('nav.contact') }}</a></li>
              </ul>
            </div>
            <div class="col-md-4 text-right">
              <p class="copyright">京公网安备 110101020053577号 | 京ICP备12044010号</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    currentLang() {
      return this.$i18n.locale === 'zh' ? '中文' : 'English'
    }
  },
  methods: {
    switchLang(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('language', lang)
      this.collapseNav()
    },
    linkHandler(path) {
      this.$router.push(path)
      this.collapseNav()
    },
    collapseNav() {
      const navbar = document.getElementById('navbarNav')
      if (navbar && navbar.classList.contains('show')) {
        document.querySelector('.navbar-toggler').click()
      }
    }
  }
}
</script>

<style lang="less">
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.navbar {
  // box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 0.5rem 1rem;
  
  .nav-link {
    color: #333 !important;
    font-size: 16px;
    padding: 1rem 1.2rem !important;
    
    &:hover {
      color: #007bff !important;
    }

    &.router-link-exact-active {
      border-bottom: 2px solid #007bff;
      color: #007bff !important;
    }
  }
  
  .navbar-brand {
    padding: 0;
  }
}

.content-wrapper {
  flex: 1;
  padding-top: 80px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 60px 0 20px;
  margin-top: 30px;

  .footer-section {
    h5 {
      color: #fff;
      margin-bottom: 20px;
      font-size: 1.2rem;
      
      .flag-icon {
        width: 24px;
        margin-right: 8px;
      }
    }

    p {
      color: #ccc;
      margin-bottom: 8px;
      font-size: 1.0rem;
    }
  }

  .qr-code {
    img {
      width: 150px;
      height: 150px;
      margin-bottom: 10px;
    }
    
    p {
      color: #ccc;
      font-size: 0.9rem;
    }
  }

  .footer-bottom {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid rgba(255,255,255,0.1);

    .footer-links {
      list-style: none;
      padding: 0;
      margin: 0;
      
      li {
        display: inline-block;
        margin-right: 20px;
        
        a {
          color: #ccc;
          text-decoration: none;
          font-size: 0.9rem;
          
          &:hover {
            color: #fff;
          }
        }
      }
    }

    .copyright {
      color: #ccc;
      font-size: 0.9rem;
      margin: 0;
    }
  }
}

// 移动端适配
@media (max-width: 768px) {
  .row {
  margin: 0;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding: 0;
}
  .footer {
    text-align: center;
    
    .footer-bottom {
      .footer-links {
        margin-bottom: 20px;
        
        li {
          display: block;
          margin: 10px 0;
        }
      }
      
      .text-right {
        text-align: center !important;
      }
    }
  }
}
</style>
