/*
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2024-12-02 15:23:46
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-02-13 11:35:29
 * @FilePath: /ww-swisstyle/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/reset.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueI18n from 'vue-i18n';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
// import '@fortawesome/fontawesome-free/css/all.css'

// 引入语言资源文件
import enLocale from './lang/en';
import zhLocale from './lang/zh';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'zh', // 默认语言
  messages: {
    en: enLocale,
    zh: zhLocale
  }
});

Vue.config.productionTip = false;
Vue.use(ElementUI);

// 配置 axios 默认值
axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://api.example.com'; // 设置基础URL
axios.defaults.timeout = 10000; // 设置超时时间
axios.defaults.headers.common['Content-Type'] = 'application/json';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return response.data;
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 将 axios 挂载到 Vue 原型上
Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
