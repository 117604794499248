/*
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2025-01-20 20:25:48
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-02-13 11:48:19
 * @FilePath: /ww-swisstyle/src/lang/en.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    common: {
      hello: 'Hello',
      welcome: 'Welcome to our website'
    },
    nav: {
      home: 'Home',
      about: 'About Us',
      clients: 'Clients',
      team: 'Team',
      contact: 'Contact',
      swiss: 'Swiss HQ'
    },
    home: {
      banner: {
        title: 'Swisstyle Packaging Solutions',
        subtitle: 'Professional packaging design and manufacturing services',
        contact: 'Contact Us'
      },
      services: {
        title: 'Our Services',
        items: [
          {
            title: 'Packaging Design',
            desc: 'Professional packaging design team providing innovative solutions'
          },
          {
            title: 'Manufacturing',
            desc: 'Advanced manufacturing process ensuring product quality'
          },
          {
            title: 'Global Delivery',
            desc: 'Reliable worldwide logistics network'
          }
        ]
      },
      about: {
        title: 'About Us',
        desc: 'Swisstyle is a Swiss company focused on packaging solutions, dedicated to providing the highest quality service to our clients.',
        features: [
          'Professional Design Team',
          'Advanced Manufacturing',
          'Strict Quality Control',
          'Premium Customer Service'
        ]
      },
      contact: {
        title: 'Contact Us',
        desc: 'Scan the QR code below for more information'
      },
      carousel: {
        learnMore: 'Learn More',
        slide1: {
          title: 'Innovative Packaging Solutions',
          desc: 'Unique packaging design to enhance your brand value'
        },
        slide2: {
          title: 'Global Logistics Network',
          desc: 'Worldwide delivery network ensuring on-time delivery'
        },
        slide3: {
          title: 'Sustainability',
          desc: 'Eco-friendly materials, green packaging for our planet'
        }
      }
    },
    footer: {
      swiss: {
        title: 'Switzerland'
      },
      china: {
        title: 'China'
      },
      followUs: 'Follow Us',
      scanQR: 'Scan QR code to follow WeChat'
    },
    aboutUs: {
      desc1: 'Swisstyle Packaging Design was founded in 1970 in Lausanne, Switzerland out of pure passion for packaging design. From the very beginning, we have had the privilege of working with many of the world\'s best-known brands, providing ideas and designs for outer packaging on a wide range of products, especially in the field of food packaging design. Thanks to our creativity, professionalism, and adaptability, we\'ve allowed our clients to gain market shares and increase revenues through impactful product packaging design, which has made our designs, and the brands they represent, renowned throughout Europe, South America, Africa and Asia!',
      highlight: 'With the rise of new food segments and a booming market, China was the next turn we could not afford to miss.',
      desc2: 'In 2005, Swisstyle took its activities and services to new levels by opening a branch office in the heart of the Chinese capital, Beijing. With several key segments of the food industries experiencing skyrocketing growth in China, having a Chinese presence and local team made all the difference in making ourselves a household name in the regional packaging design landscape. This capacity to adapt is precisely represented by our logo, nature\'s master in versatile "packaging design", the chameleon.',
      desc3: 'Drawing from experience in designing product packaging on other continents and in Switzerland, Swisstyle quickly created lasting partnerships with the biggest names in the Chinese food industry. Deep understanding of local consumer behavior and culture combined with top level expertise allowed Swisstyle to take part in the very fast-growing Chinese dairy product industry.',
desc4: 'For the past decade and half, Swistyle maintain remarkable balance between its Swiss and Chinese activities, with sometimes both teams joining forces to make the best of their respective creativity and expertise.',
      desc5: 'With a steady and ever-growing business base in China, we are now implementing a new strategy for our activities in Switzerland.',
      signature: 'Contact us to learn more about our services and how we can turn your product into a successful brand. '
    }
};
  
  