/*
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2024-12-02 15:23:46
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-01-22 17:08:13
 * @FilePath: /vue2-admin/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%A
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
  },
 
   {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/detail.vue'),
   },
   {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue'),
   },
   {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue'),
   },
   {
    path: '/team',
    name: 'team',
    component: () => import('../views/team.vue'),
   },
   {
    path: '/tab',
    name: 'tab',
    component: () => import('../views/tab.vue'),
   }
 
];


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
