<!--
 * @Author: 吴伟 15711376175@163.com
 * @Date: 2024-12-02 17:44:47
 * @LastEditors: 吴伟 15711376175@163.com
 * @LastEditTime: 2025-02-11 15:33:22
 * @FilePath: /vue2-admin/src/views/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE$
-->
<template>
  <div class="index-container">
    <!-- 轮播图区域 -->
    <section class="carousel-section">
      <div id="mainCarousel" class="carousel slide" data-ride="carousel" data-interval="3000">
        <ol class="carousel-indicators">
          <li v-for="(slide, index) in carouselConfig.slides" 
              :key="index"
              data-target="#mainCarousel"
              :data-slide-to="index"
              :class="{ active: index === 0 }">
          </li>
        </ol>
        <div class="carousel-inner">
          <div v-for="(slide, index) in carouselConfig.slides" 
               :key="index"
               :class="['carousel-item', { active: index === 0 }]">
            <img :src="slide.pic" class="d-block w-100" :alt="`slide-${index + 1}`">
          </div>
        </div>
        <!-- <a class="carousel-control-prev" href="#mainCarousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#mainCarousel" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a> -->
      </div>
    </section>

    <!-- Banner区域 -->
    <!-- <section class="banner">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <h1>{{ $t('home.banner.title') }}</h1>
            <p>{{ $t('home.banner.subtitle') }}</p>
            <button class="btn btn-primary">{{ $t('home.banner.contact') }}</button>
          </div>
          <div class="col-lg-6">
            <img src="@/assets/banner-image.jpg" alt="banner" class="img-fluid">
          </div>
        </div>
      </div>
    </section> -->

    <!-- 服务展示区域 -->
    <section class="services-showcase">
      <div class="container">
        <div class="services-wrapper">
          <!-- 左侧标题 -->
          <div class="section-header">
            <h2 class="main-title">Services</h2>
            <h3 class="sub-title">我们的服务</h3>
          </div>

          <!-- 中间内容 -->
          <div class="services-content">
            <div class="service-column" v-for="(column, index) in serviceColumns" :key="index">
              <div class="service-title" v-for="(item, itemIndex) in column" :key="itemIndex">
                {{ $i18n.locale === 'zh' ? item.productCnDesc : item.productEnDesc }}
              </div>
            </div>
          </div>

          <!-- 右侧二维码 -->
          <div class="qr-code">
            <img src="@/assets/qr-code.jpg" alt="二维码">
            <p>了解更多请关注公众号</p>
          </div>
        </div>
      </div>
    </section>

    <!-- 客户展示区域 -->
    <section class="clients-showcase">
      <div class="container p-0">
        <div class="section-header text-center">
          <h2 class="main-title">Clients</h2>
          <h3 class="sub-title">我们的客户</h3>
          <p class="desc">{{ clientsConfig[$i18n.locale].desc }}</p>
        </div>

        <div class="clients-grid">
          <div class="client-item" v-for="(client, index) in clientsConfig.logos" :key="index">
            <img :src="client.imageUrl" >
          </div>
        </div>
      </div>
    </section>
    <section class="product-showcase">
      <div class="container-fluid product-container">
        <div class="row g-0 m-0">
          <div class="col-12 col-sm-6 col-lg-4 p-0" v-for="(image, index) in productList" :key="index">
            <div class="product-item" @click="lookDetail(image)">
              <img :src="image.imageUrl" alt="" class="w-100">
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'Index',
  data() {
    return {
      carouselConfig: {
        slides: [
          
        ]
      },
      services: [
        // '包装策略规划',
        // '产品命名与梳理',
        // '年度包装与设计服务',
        // '全产品线包装设计及延展',
        // '主视觉及物料设计',
        // '包装造型与瓶型设计',
        // '包装插画设计',
        // '包装陈列设计',
        // '饼干麦片包装',
        // '调味品包装',
        // '巧克力糖果包装',
        // '咖啡包装',
        // '乳制品包装',
        // '饮品包装',
        // '婴儿食品包装'
      ],
      aboutConfig: {
        imageUrl: 'https://picsum.photos/800/600?random=4',
        zh: {
          title: '关于我们',
          desc: 'Swisstyle是一家专注于包装解决方案的瑞士公司，我们致力于为客户提供最优质的服务。',
          features: [
            '专业的设计团队',
            '先进的制造工艺',
            '严格的品质控制',
            '优质的客户服务'
          ]
        },
        en: {
          title: 'About Us',
          desc: 'Swisstyle is a Swiss company focused on packaging solutions, dedicated to providing the highest quality service to our clients.',
          features: [
            'Professional Design Team',
            'Advanced Manufacturing',
            'Strict Quality Control',
            'Premium Customer Service'
          ]
        }
      },
      clientsConfig: {
        zh: {
          desc: '我们更心感谢我们的合作伙伴，在我们帮助他们创造产品竞争力和品牌价值的同时，他们严谨的工作态度及对其品牌耐心无畏的打磨，也激励着我们不断超越自己，为我们积累了宝贵的经验。'
        },
        en: {
          desc: 'We are grateful to our partners. While we help them create product competitiveness and brand value, their rigorous work attitude and patient brand building also inspire us to continuously surpass ourselves, accumulating valuable experience.'
        },
        logos: [
         
        ]
      },
      productList: []
    }
  },
  components: {

  },
   
  methods: {
    switchLang(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('language', lang)
    },
    // 获取轮播图数据
    async fetchCarouselData() {
      try {
        const data = await api.getCarouselData();
        data.rows.forEach(item => {
          item.pic = process.env.VUE_APP_OSS_URL + item.pic
        })
        //  根据orderNum 从小到大排序
        data.rows.sort((a, b) => a.orderNum - b.orderNum)
        this.carouselConfig.slides = data.rows
      } catch (error) {
        console.error('获取轮播图数据失败:', error);
      }
    },
    
    // 获取服务列表
    async fetchServices() {
      try {
        const data = await api.getServices();
        this.services = data.rows;
      } catch (error) {
        console.error('获取服务列表失败:', error);
      }
    },
    async fetchProductList() {
      const data = await api.getProductList('product');
      data.rows.forEach(item => {
        item.imageUrl = process.env.VUE_APP_OSS_URL + item.imageUrl
      })
      this.productList = data.rows;
    },
    
    // 获取客户数据
    async fetchClientsData() {
      try {
        const data = await api.getClients();
        data.rows.forEach(item => {
          item.imageUrl = process.env.VUE_APP_OSS_URL + item.imageUrl
        })
        this.clientsConfig.logos = data.rows;
      } catch (error) {
        console.error('获取客户数据失败:', error);
      }
    },
    lookDetail(item) {
     
        this.$router.push({ path: '/detail', query: { id: item.productId } })
      
    }
  },
  async mounted() {
    // 页面加载时获取数据
    await Promise.all([
      this.fetchCarouselData(),
      this.fetchServices(),
      this.fetchProductList(),
      this.fetchClientsData()
    ]);
  },
  computed: {
    serviceColumns() {
    

      // 将服务分成三列
      const column1 = this.services.slice(0, 6);
      const column2 = this.services.slice(6, 11);
      const column3 = this.services.slice(11);

      return [column1, column2, column3];
    }
  }
};
</script>

<style lang="less" scoped>
.index-container {
  .carousel-section {
    .carousel {
      .carousel-inner {
        .carousel-item {
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }
      
      .carousel-control-prev,
      .carousel-control-next {
        width: 5%;
        opacity: 0.8;
        
        &:hover {
          opacity: 1;
        }
      }
      
      .carousel-indicators {
        li {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: 0 8px;
          background-color: rgba(255,255,255,0.5);
          
          &.active {
            background-color: white;
          }
        }
      }
    }
  }

  .banner {
    padding: 100px 0;
    background: #f8f9fa;
    
    h1 {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
    
    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
  }

  .services-showcase {
    padding: 80px 0;
    background: #fff;

    .services-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 40px;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }

    .section-header {
      flex: 0 0 200px;
      
      .main-title {
        font-size: 48px;
        margin-bottom: 10px;
        color: #333;
        font-weight: 300;
      }
      
      .sub-title {
        font-size: 24px;
        color: #666;
        font-weight: 400;
      }
    }

    .services-content {
      flex: 1;
      display: flex;
      gap: 40px;
    }

    .service-column {
      flex: 1;

      .service-title {
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          color: #007bff;
          transform: translateX(5px);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .qr-code {
      flex: 0 0 150px;
      text-align: center;

      img {
        width: 150px;
        height: 150px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        color: #666;
        margin: 0;
      }
    }
  }

 
  .clients-showcase {
    padding: 30px 0;
    background: #fff;

    .section-header {
      margin-bottom: 20px;
      
      .main-title {
        font-size: 2.2rem;
        margin-bottom: 0.3rem;
        color: #333;
        font-weight: 300;
      }
      
      .sub-title {
        font-size: 1.3rem;
        color: #666;
        font-weight: 400;
        margin-bottom: 1rem;
      }

      .desc {
        max-width: 800px;
        margin: 0 auto;
        color: #666;
        font-size: 1rem;
        line-height: 1.5;
        padding: 0 15px;
      }
    }

    .clients-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 15px 4px;
      padding: 0 8px;

      .client-item {
        background: #fff;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        // filter: grayscale(100%);
        opacity: 1;

        &:hover {
          opacity: 1;
          // filter: grayscale(0);
          transform: translateY(-2px);
        }

        img {
          max-width: 100%;
          max-height: 100px;
          object-fit: contain;
        }
      }
    }
  }

  .product-showcase {
    background: #fff;
    padding: 0;
    
    .product-container {
      padding: 0 20px;
      max-width: 100%;
    }
    
    .product-item {
      position: relative;
      overflow: hidden;
      line-height: 0;
      
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease;
      }
    }
  }
}

@media (max-width: 768px) {
  .banner {
    text-align: center;
    padding: 60px 0;
    
    h1 {
      font-size: 2.5rem;
    }
  }
  
  .services-showcase {
    padding: 40px 0;

    .section-header {
      margin-bottom: 30px;
      text-align: center;
      
      .main-title {
        font-size: 32px;
      }
      
      .sub-title {
        font-size: 20px;
      }
    }

    .services-content {
      flex-direction: column;
      gap: 30px;
      padding: 0 20px;
    }

    .service-column {
      .service-title {
        margin-bottom: 15px;
        text-align: center;
      }
    }
  }

  .clients-showcase {
    padding: 20px 0;

    .section-header {
      .main-title {
        font-size: 1.8rem;
      }
      
      .sub-title {
        font-size: 1.1rem;
      }

      .desc {
        font-size: 0.9rem;
        padding: 0 15px;
      }
    }

    .clients-grid {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 5px;

      .client-item {
        padding: 4px;
      }
    }
  }

  .product-showcase {
    padding: 30px 0;

    .row {
      > div {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .services-showcase {
    .services-wrapper {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .section-header {
      flex: none;
      margin-bottom: 30px;
    }

    .services-content {
      width: 100%;
    }

    .qr-code {
      margin-top: 30px;
    }
  }

  .product-showcase {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .product-showcase {
    padding: 0;
    
    .product-item {
      img {
        max-height: 300px;
      }
    }
  }
}

.index {
  width: 100%;
  height: 100%;
}

.lang-switch {
  margin: 20px;
}
.lang-switch button {
  margin: 0 10px;
  padding: 5px 10px;
}
</style>

